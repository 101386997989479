import * as func from './functions';
import React, {useCallback, useEffect, useRef} from 'react';
const generateWindowListener = (validTarget, handleMessage) => event => {
    const message = func.isJson(event.data) ? JSON.parse(event.data) : event.data
    message.ports = event.ports
    if (validTarget(message)) {
        handleMessage(message)
    }
}

//Custom Hook to ensure that the event listener updates if the target or handler changes
export const useWindowListener = (validTarget, handleMessage) => {
    useEffect(() => {
        const listener = generateWindowListener(validTarget, handleMessage)
        window.addEventListener('message', listener)
        return () => window.removeEventListener('message', listener)
    }, [validTarget, handleMessage])
}

export const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback = useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  return debouncedCallback;
};