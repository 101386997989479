import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as func from './HostedFields/functions'

import HostedFields from './HostedFields'
import CardPresent from './CardPresent'
import Overlay from './Overlay'
import Button from './Button'
import CheckoutQR from './CheckoutQr'

// const HostedFields = lazy(() =>
//     import ('./HostedFields'));
// 
// const CardPresent = lazy(() =>
//     import ('./CardPresent'));
// 
// const Overlay = lazy(() =>
//     import ('./Overlay'));
// 
// const Button = lazy(() =>
//     import ('./Button'));
// 
// const CheckoutQR = lazy(() =>
//     import ('./CheckoutQr'));

const field = {
  bankCode: {
    type: "routing-number",
    placeholder: "Routing Number",
    autoComplete: "",
    aria: "Bank routing number",
    formatter: func.formatNumbers,
    numeric: true
  },
  accountName: {
    type: "account-name",
    placeholder: "Name on the Account",
    autoComplete: "name",
    aria: "Name on bank account"
  },
  accountType: {
    type: "account-type",
    placeholder: "Account Type",
    autoComplete: "",
    aria: "Bank account type"
  },
  accountNumber: {
    type: "account-number",
    placeholder: "Account Number",
    autoComplete: "",
    aria: "Bank account number",
    formatter: func.formatNumbers,
    numeric: true
  },
  cardNumber: {
    type: "card-number",
    placeholder: "Card Number",
    autoComplete: "cc-number",
    aria: "Credit or debit card number",
    formatter: func.formatCard,
    numeric: true
  },
  cardCvv: {
    type: "card-cvv",
    placeholder: "CVV",
    autoComplete: "",
    aria: "Credit or debit card security code",
    formatter: func.formatCvv,
    numeric: true
  },
  cardExp: {
    type: "card-exp",
    placeholder: "MM/YY",
    autoComplete: "",
    aria: "Credit or debit card expiration",
    formatter: func.formatDate,
    numeric: true
  },
  cardName: {
    type: "card-name",
    placeholder: "Name on Card",
    autoComplete: "name",
    aria: "Name on credit or debit card"
  },
  line1: {
    type: "billing-line1",
    placeholder: "Address Line 1",
    autoComplete: "address-line1",
    aria: "Billing address line one"
  },
  line2: {
    type: "billing-line2",
    placeholder: "Address Line 2",
    autoComplete: "",
    aria: "Billing address line two"
  },
  city: {
    type: "billing-city",
    placeholder: "City",
    autoComplete: "",
    aria: "Billing address city"
  },
  state: {
    type: "billing-state",
    placeholder: "State",
    autoComplete: "",
    aria: "Billing address state"
  },
  zip: {
    type: "billing-zip",
    placeholder: "Zip",
    autoComplete: "",
    aria: "Billing address zip code",
    numeric: true
  },
  cashName: {
    type: "cash-name",
    placeholder: "Full Name",
    autoComplete: "name",
    aria: "Cash Full Name"
  },
  cashContact: {
    type: "cash-contact",
    placeholder: "Phone or Email",
    autoComplete: "",
    aria: "Cash contact info"
  }

}

export default function App() {

  // return (
  //   <Router>
  //     <Routes>
  //       <Route path="/account-number" element={<HostedFields field={field.accountNumber} />} />
  //       <Route path="/account-type" element={<HostedFields field={field.accountType} />} />
  //       <Route path="/routing-number" element={<HostedFields field={field.bankCode} />} />
  //       <Route path="/account-name" element={<HostedFields field={field.accountName} />} />
  //       <Route path="/card-name" element={<HostedFields field={field.cardName} />} />
  //       <Route path="/card-number" element={<HostedFields field={field.cardNumber} />} />
  //       <Route path="/card-cvv" element={<HostedFields field={field.cardCvv} />} />
  //       <Route path="/card-exp" element={<HostedFields field={field.cardExp} />} />
  //       <Route path="/billing-line1" element={<HostedFields field={field.line1} />} />
  //       <Route path="/billing-line2" element={<HostedFields field={field.line2} />} />
  //       <Route path="/billing-city" element={<HostedFields field={field.city} />} />
  //       <Route path="/billing-state" element={<HostedFields field={field.state} />} />
  //       <Route path="/billing-zip" element={<HostedFields field={field.zip} />} />
  //       <Route path="/cash-name" element={<HostedFields field={field.cashName} />} />
  //       <Route path="/cash-contact" element={<HostedFields field={field.cashContact} />} />
  //       <Route path="/card-present" element={<CardPresent />} />
  //       <Route path="/overlay" element={<Overlay />} />
  //       <Route path="/checkout_button" element={<Button />} />
  //       <Route path="/checkout_qr" element={<CheckoutQR />} />
  //     </Routes>
  //   </Router>
  // );
  return (
      <Router>
        <Suspense fallback={<span/>}>
        <Switch>
          <Route path="/account-number">
            <HostedFields field={field.accountNumber} />
          </Route>
          <Route path="/account-type">
            <HostedFields field={field.accountType} />
          </Route>
          <Route path="/routing-number">
            <HostedFields field={field.bankCode} />
          </Route>
          <Route path="/account-name">
            <HostedFields field={field.accountName} />
          </Route>
          <Route path="/card-name">
            <HostedFields field={field.cardName} />
          </Route>
          <Route path="/card-number">
            <HostedFields field={field.cardNumber} />
          </Route>
          <Route path="/card-cvv">
            <HostedFields field={field.cardCvv} />
          </Route>
          <Route path="/card-exp">
            <HostedFields field={field.cardExp} />
          </Route>
          <Route path="/billing-line1">
            <HostedFields field={field.line1} />
          </Route>
          <Route path="/billing-line2">
            <HostedFields field={field.line2} />
          </Route>
          <Route path="/billing-city">
            <HostedFields field={field.city} />
          </Route>
          <Route path="/billing-state">
            <HostedFields field={field.state} />
          </Route>
          <Route path="/billing-zip">
            <HostedFields field={field.zip} />
          </Route>
          <Route path="/cash-name">
            <HostedFields field={field.cashName} />
          </Route>
          <Route path="/cash-contact">
            <HostedFields field={field.cashContact} />
          </Route>
          <Route path="/card-present">
            <CardPresent />
          </Route>
          <Route path="/overlay">
            <Overlay />
          </Route>
          <Route path="/checkout_button">
            <Button />
          </Route>
          <Route path="/checkout_qr">
            <CheckoutQR />
          </Route>
        </Switch>
        </Suspense>
      </Router>
  );
}
