import { useEffect, useState, useCallback } from "react";
import * as network from "../HostedFields/network";

const useWebSocket = ({ onOpen, onError, onMessage, ptToken }) => {
    const [websocket, setWebsocket] = useState(undefined)
    const [connected, setConnected] = useState(false)

    //Initializes the web socket and then sends a ready message
    useEffect(() => {
        if (websocket && connected === false) {
            const empty = () => {}
            const READY_ACTIONS = {
                0: () => {
                    //passing the websocket into the onOpen function, so it can be referenced before initialization
                    websocket.onopen = () => {
                        onOpen(websocket)
                    }
                    websocket.onmessage = onMessage
                    websocket.onerror = onError
                    setConnected(true)
                },
                1: empty,
                2: empty,
                3: empty
            }
            READY_ACTIONS[websocket.readyState]()
        }
    }, [ptToken, onMessage, onError, onOpen, websocket, connected])

    useEffect(() => {
        if (ptToken && !websocket) {
            let socket = network.createSocket(ptToken)
            setWebsocket(socket)
        }
    }, [ptToken, websocket])

    const resetWebsocket = useCallback(() => {
        websocket.close(1000, "Closing the websocket to reset the connection")
        setWebsocket(undefined)
        setConnected(false)
    }, [websocket])

    return {websocket, resetWebsocket}
}

export default useWebSocket