import React, {useCallback} from "react";
import * as util from "../util";
import whiteLogo from "../Images/pay_theory_primary_logo_white.svg";

const Overlay = () => {
    const query = util.useQuery();
    const token = query.get("token")
    const json = decodeURI(token)
    const decodedJson = window.atob(json)
    const object = JSON.parse(decodedJson)
    const origin = object.origin

    const sendFocusMessage = useCallback(() => {
        util.sendMessage({
            type: `pt-overlay:relaunch`
        }, origin)
    }, [])

    const sendCancelMessage = useCallback(() => {
        util.sendMessage({
            type: `pt-overlay:cancel`
        }, origin)
    }, [])

    return (
        <div id="pay-theory-overlay">
            <button
                id="pay-theory-overlay-close-button"
                onClick={sendCancelMessage}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
            </button>
            <div id="pay-theory-overlay-message-wrapper">
                <img
                    src={whiteLogo}
                />
                <p>
                    Don’t see the secure Pay Theory browser? We’ll help you re-launch the
                    window to complete your purchase.
                </p>
                <button
                onClick={sendFocusMessage}
                >CLICK TO CONTINUE</button>
            </div>
            <style global="true" jsx="true">
                {`
            #pay-theory-overlay {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #000000b8 0% 0% no-repeat padding-box;
            }
                
            #pay-theory-overlay-close-button {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 20px;
                right: 20px;
                border: none;
                background: none;
                height: 40px;
                width: 40px;
                padding: 12px;
                border-radius: 16px;
                cursor: pointer;
                transition: padding 0.3s ease;
            }
            
            #pay-theory-overlay-close-button i {
                font-size: 24px;
                color: white;
                transition: font-size 0.3s ease;
            }
            
            #pay-theory-overlay-close-button:hover i {
                font-size: 16px;
                color: black;
            }
            

            #pay-theory-overlay-close-button svg {
                filter: invert(100%);
            }

            #pay-theory-overlay-close-button:hover,
            #pay-theory-overlay-close-button:focus {
                background-color: #cac4ca29;
                padding: 15px;
            }

            #pay-theory-overlay-close-button:hover svg,
            #pay-theory-overlay-close-button:focus svg {
                filter: invert(0%);
            }

            #pay-theory-overlay-message-wrapper {
                max-width: 390px;
                width: 100%;
                text-align: center;
                position: absolute;
                margin: auto;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            #pay-theory-overlay-message-wrapper img {
                width: 165px;
                margin: 0px 0px 16px;
            }

            #pay-theory-overlay-message-wrapper p {
                color: white;
                margin: 0px 0px 16px;
                font-size: 16px;
                font-weight: 200;
                font-family: halyard-text, inter, arial, sans-serif;
            }

            #pay-theory-overlay-message-wrapper button {
                border: none;
                background: none;
                color: white;
                cursor: pointer;
                font-family: ibm-plex-mono, monaco, monospace;
            }

            #pay-theory-overlay-message-wrapper button:hover {
                text-decoration: underline;
            }
            `}
            </style>
        </div>
    );
};

export default Overlay;
