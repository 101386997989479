import React, {useCallback, useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import * as model from './model'
import * as util from '../util'
import * as network from "../HostedFields/network";
import * as func from '../HostedFields/functions'
// import Amplify from 'aws-amplify';

const getTiming = () => Math.round(Date.now())

const ERROR_TYPE = "error"
const HOST_TOKEN = "host:hostToken"
const HOST_TOKEN_TYPE = "host_token"

// Amplify.configure({
//     Auth: {
//
//         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//         identityPoolId: process.env.REACT_APP_IDENTITY_POOL, //'us-east-1:ffd0e720-da44-45ab-ac61-66422d32a552',
//
//         // REQUIRED - Amazon Cognito Region
//         region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',
//
//         // OPTIONAL - Amazon Cognito User Pool ID
//         userPoolId: process.env.REACT_APP_USER_POOL, //'us-east-1_ut0KvX8ix',
//
//         // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//         userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT, //'7275mnpka5v5d0nmk1iav5de8m',
//
//         // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//         mandatorySignIn: false
//     },
//     aws_appsync_graphqlEndpoint: `https://internal.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`,
//     aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
//     aws_appsync_authenticationType: 'AWS_IAM',
// });
//
//
//
// async function signIn() {
//     try {
//         let user = await Amplify.Auth.currentCredentials();
//     } catch (error) {
//         console.log('Unable to connect to AWS', error);
//     }
// }
//
// signIn();

const CardPresent = () => {
    const {token} = useParams()
    const { origin , token: ptToken} = model.parseToken(token)
    const [websocket, setWebsocket] = useState(null)
    const [isConnected, setIsConnected] = useState(false)
    const [merchantUid, setMerchantUid] = useState(null)

    const generateWindowListener = (validTarget, handleMessage) => event => {
        const message = func.isJson(event.data) ? JSON.parse(event.data) : event.data
        if (validTarget(message)) {
            handleMessage(message)
        }
    }

    const activateTypeMessage = message => typeof message.type === 'string' && message.type === 'pt-static:activate'

    const activationHandler = useCallback((message) => {
        let body = message.data
        const variables = {
            payor: model.convertToPayorInput(body.payorInfo),
            send_receipt: body.sendReceipt,
            reference: body.reference,
            recurring_id: body.recurringId,
            receipt_description: body.receiptDescription,
            payor_id: body.payorId,
            payment_parameters_name: body.paymentParameters,
            metadata: body.metadata,
            merchant_uid: merchantUid,
            invoice_id: body.invoiceId,
            fee_mode: body.feeMode?.toUpperCase(),
            fee: body.fee,
            device_id: body.deviceId,
            currency: body.currency,
            amount: body.amount,
            account_code: body.accountCode,
            timezone: body.timezone
        }
        if (merchantUid) {
            // model.activateCardPresentDevice(variables, origin)
        } else {
            util.sendMessage({
                type: `pt-static:error`,
                error: 'NO_TOKEN: Unable to fetch and authenticate the host_token.',
                field: 'card-present'
            }, origin)
        }
    }, [merchantUid])

    const activateCallback = generateWindowListener(activateTypeMessage, activationHandler)

    useEffect(() => {
        window.addEventListener('message', activateCallback)
        return () => { window.removeEventListener('message', activateCallback) }
    }, [activateCallback])

    const messageCallback = useCallback((message) => {
        const data = JSON.parse(message.data)
        let body = data?.body

        switch (data?.type) {
            case ERROR_TYPE:
                util.sendMessage({
                    type: `pt-static:error`,
                    error: `SOCKET_ERROR: ${body}`,
                    field: 'card-present'
                }, origin)
                break;
            case HOST_TOKEN_TYPE:
                setMerchantUid(body.merchantUid)
                const message = {
                    type: model.CARD_PRESENT_TYPE,
                    body: {
                        status: "READY"
                    }
                }
                util.sendMessage(message, origin)
                break;
            default:
                util.sendMessage({
                    type: `pt-static:error`,
                    error: 'SOCKET_ERROR: There was an error with the socket.',
                    field: 'card-present'
                }, origin)
                break;
        }
    }, [origin])

    //Initializes the web socket and then sends a ready message
    useEffect(() => {
        if (websocket && isConnected === false) {
            const empty = () => {}
            const READY_ACTIONS = {
                0: () => {
                    websocket.onopen = () => {
                        let message = { action: HOST_TOKEN, encoded: window.btoa(JSON.stringify({ptToken, origin, timing: getTiming()})) }
                        websocket.send(JSON.stringify(message));
                    }
                    websocket.onmessage = messageCallback
                    websocket.onerror = () => {
                        util.sendMessage({
                            type: `pt-static:error`,
                            error: 'SOCKET_ERROR: There was an error with the socket.',
                            field: 'card_present'
                        }, origin)
                    }
                    setIsConnected(true)
                },
                1: empty,
                2: empty,
                3: empty
            }
            READY_ACTIONS[websocket.readyState]()
        }
    }, [ptToken, messageCallback, origin, isConnected, websocket])

    useEffect(() => {
            if (ptToken && !websocket) {
                let socket = network.createSocket(ptToken)
                setWebsocket(socket)
            }
        }, [ptToken, websocket])

    return <div className="pay-theory-card-present"/>
}

export default CardPresent
