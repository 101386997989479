import React, { useEffect } from "react";

const Radio = ({ styles, value, setValue, placeholders }) => {
  const CHECKING = "CHECKING";
  const SAVINGS = "SAVINGS";

  const calcInnerDiameter = (value) => {
    const initial = Math.floor((style.width * 2) / 3);
    const diff = value - initial;
    if (diff % 2 === 1) {
      return initial + 1;
    } else {
      return initial;
    }
  };

  const calcStrokeWidth = (value) => {
    let result = Math.floor(style.width / 16);
    return result || 1;
  };

  const style = styles || {};

  const width = style.width || 16;
  const strokeColor = style.stroke || "black";
  const fill = style.fill || "black";
  const innerDiameter = style.width ? calcInnerDiameter(style.width) : 10;
  const stroke = style.width ? calcStrokeWidth(style.width) : 1;
  const innerOffset = (width - innerDiameter) / 2;

  useEffect(() => {
      setValue(CHECKING)
  }, [])

  const finalStyles = {
    "--width": `${width}px`,
    "--stroke-color": strokeColor,
    "--fill": fill,
    "--inner-width": `${innerDiameter}px`,
    "--stroke": `${stroke}px`,
    "--inner-offset": `${innerOffset}px`
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="radio" style={finalStyles}>
      <label for="checking" style={style.text}>
        {placeholders?.checking || "Checking"}
        <input
          type="radio"
          id="checking"
          name="account_type"
          value={CHECKING}
          checked={value === CHECKING}
          onChange={handleChange}
        />
        <span class="checkmark"></span>
      </label>
      <label for="savings" style={style.text}>
        {placeholders?.savings || "Savings"}
        <input
          type="radio"
          id="savings"
          name="account_type"
          value={SAVINGS}
          checked={value === SAVINGS}
          onChange={handleChange}
        />
        <span class="checkmark"></span>
      </label>
      <style global="true" jsx="true">
        {`
          .radio {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .radio > * {
            margin-right: calc(var(--width));
          }

          label {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: calc(var(--width) + var(--width) / 2);
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: calc(var(--width) + var(--stroke) * 2);
          }

          /* Hide the browser's default radio button */
          label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: var(--width);
            width: var(--width);
            border-radius: 50%;
            border: var(--stroke) solid var(--stroke-color);
          }

          /* On mouse-over, add a grey background color */
          label:hover input ~ .checkmark {
            background-color: rgba(0, 0, 0, 0.1);
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          label input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          label .checkmark:after {
            top: var(--inner-offset);
            left: var(--inner-offset);
            width: var(--inner-width);
            height: var(--inner-width);
            border-radius: 5000000px;
            background: var(--fill);
          }
        `}
      </style>
    </div>
  );
};

export default Radio;
